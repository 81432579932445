import { Route, Switch } from "react-router-dom";
//路由页面
import FaceResult from "./common/FaceResult";
import Home from "common/Home";
import Jdcx from "./pages/Jdcx";
import Zshy from "./pages/Zshy";
import Bszn from "./pages/Bszn";
import Ytcf from "./pages/Ytcf";
import Yfwfzm from "./pages/Yfwfzm";
import Yzym from "./pages/Yzym";
import Jt from "./pages/Jt";
import Zmhy from "./pages/Zmhy";
import Zsxx from "./pages/zxxw/Zsxx";
import Zxxw from "./pages/zxxw/Zxxw";
import Sdq from "./pages/zxxw/Sdq";
import Xwbl from "./pages/zxxw/Xwbl";
import Sqr from "./pages/zxxw/Sqr";
import Chapter from "./pages/Chapter";
import Success from "./pages/zxxw/Success";
import PersonalCenter from "./common/PersonalCenter";
import Zszt from "./pages/zszt";
import House from "./pages/House";
import HouseItem from "./pages/HouseItem";
import HouseInfo from "./pages/HouseInfo";
import HouseInfoSf from "./pages/housequery/index";
import Fwzt from "./pages/housequery/Fwzt";
import Fwtc from "./pages/housequery/Fwtc";
import SinfJdcx from "./pages/SinfJdcx";
import DzzzYn from "./pages/dzzz/DzzzYn";
import Cxsq from "./pages/Cxsq";
import WechatPay from "./pages/pay/WechatPay";
import Bill from "./pages/Bill";
import Address from "./pages/Address";
import FaceFw from "./pages/housequery/FaceFw";
import FaceFwtc from "./pages/housequery/FaceFwtc";
import FaceFwzt from "./pages/housequery/FaceFwzt";
import Verification from "./pages/housequery/Verification";
import NoticeHH from "./pages/NoticeHH";
import Index from "./pages/ServiceGuide";
import DzzzFace from "./pages/dzzz/DzzzFace";
import Zcfg from "./pages/Zcfg";
import Jdts from "./pages/Jdts/JdtsIndex";
import Fwpj from "./pages/Fwpj/Fwpj";
import Zxyy from "./pages/Zxyy/Zxyy";
import Njgsgg from "./pages/Gsgg/Gsgg";
import SinfJdcxNJ from "./pages/SinfJdcxNJ";
import newBszn from "./pages/Bszn/Bszn";
import newZcfg from "./pages/Zcfg/Zcfg";
import SNDzzz from "./pages/suining/SNDzzz";
import HouseInfoSf_SN from "./pages/suining/House";
import FhtSn from "./pages/suining/FhtSn";
import SNPay from "./pages/suining/pay/Pay";
import HouseCheck from "./pages/housequery/HouseCheck";
import ZzhySN from "./pages/suining/Zzhy";
import Djzx from "./pages/Djzx";
import ZxyySN from "./pages/suining/Zxyy";
import Ywgl from "./pages/qj/Ywgl";
import HouseInfoSf_DZ from "./pages/suining/HouseDz";
import Bmfw from "./pages/Bmfw/Bmfw";

const routes = [
  /* ========================== */
  /* requiresAuth --> 增加权限 */
  /* children --> 嵌套路由 */
  /* ========================== */
  { exact: true, path: "/", component: Home },
  { exact: true, path: "/me", component: PersonalCenter },
  { exact: true, path: "/jdcx", component: Jdcx },
  { exact: true, path: "/zshy", component: Zshy },
  { exact: true, path: "/new_bszn", component: newBszn },
  { exact: true, path: "/bszn", component: Bszn },
  { exact: true, path: "/zxxw", component: Zxxw },
  { exact: true, path: "/ytcf", component: Ytcf },
  { exact: true, path: "/yfwfzm", component: Yfwfzm },
  { exact: true, path: "/yzym", component: Yzym },
  { exact: true, path: "/jt", component: Jt },
  { exact: true, path: "/faceAuth", component: FaceResult },
  { exact: true, path: "/zmhy", component: Zmhy },
  { exact: true, path: "/zsxx/:ywlsh", component: Zsxx },
  { exact: true, path: "/sdq/:ywlsh", component: Sdq },
  { exact: true, path: "/xwbl/:ywlsh", component: Xwbl },
  { exact: true, path: "/sqr/:ywlsh", component: Sqr },
  { exact: true, path: "/chapter", component: Chapter },
  { exact: true, path: "/zszt", component: Zszt },
  { exact: true, path: "/house", component: House },
  { exact: true, path: "/success", component: Success },
  { exact: true, path: "/houseItem", component: HouseItem },
  { exact: true, path: "/houseInfo", component: HouseInfo },
  { exact: true, path: "/houseSf", component: HouseInfoSf },
  { exact: true, path: "/houseSf_sn", component: HouseInfoSf_SN },
  { exact: true, path: "/fht_sn", component: FhtSn },
  { exact: true, path: "/fwzt", component: Fwzt },
  { exact: true, path: "/fwtc", component: Fwtc },
  { exact: true, path: "/jdcxSf", component: SinfJdcx },
  { exact: true, path: "/jdcxSfNJ", component: SinfJdcxNJ },
  { exact: true, path: "/dzzzYn", component: DzzzYn },
  { exact: true, path: "/dzzzFace", component: DzzzFace },
  { exact: true, path: "/cxsq", component: Cxsq },
  { exact: true, path: "/wechat_pay", component: WechatPay },
  { exact: true, path: "/bill", component: Bill },
  { exact: true, path: "/address", component: Address },
  { exact: true, path: "/face_fw", component: FaceFw },
  { exact: true, path: "/face_fwtc", component: FaceFwtc },
  { exact: true, path: "/face_fwzt", component: FaceFwzt },
  { exact: true, path: "/notice_hh", component: NoticeHH },
  { exact: true, path: "/service_guide", component: Index },
  { exact: true, path: "/new_zcfg", component: newZcfg },
  { exact: true, path: "/zcfg", component: Zcfg },
  { exact: true, path: "/jdts", component: Jdts },
  { exact: true, path: "/verification", component: Verification },
  { exact: true, path: "/njgsgg", component: Njgsgg },
  { exact: true, path: "/fwpj", component: Fwpj },
  { exact: true, path: "/zxyy", component: Zxyy },
  { exact: true, path: "/sndzzz", component: SNDzzz },
  { exact: true, path: "/pay_suining", component: SNPay },
  { exact: true, path: "/house_check", component: HouseCheck },
  { exact: true, path: "/zzhy_sn", component: ZzhySN },
  { exact: true, path: "/djzx", component: Djzx },
  { exact: true, path: "/zxyy_sn", component: ZxyySN },
  { exact: true, path: "/ywgl", component: Ywgl },
  { exact: true, path: "/houseSf_dz", component: HouseInfoSf_DZ },
  { exact: true, path: "/bmfw", component: Bmfw }
];

export const AppRoutes = () => (
  <>
    {/* Switch作用是唯一路径匹配，避免页面组件重复渲染  */}
    <Switch>
      {routes.map((route, index) => (
        <Route
          key={index}
          exact={route.exact}
          path={route.path}
          component={route.component}
        />
      ))}
    </Switch>
  </>
);
