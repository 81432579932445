import { useEffect } from "react";
import { HashRouter } from "react-router-dom";
import { AppRoutes } from "./router";
import appConfig from "./config/appConfig";

export default function App() {
  document.title = process.env.REACT_APP_DOMAIN_TITLE || "不动产登记中心";
  useEffect(() => {
    /* 初始化 */
    new appConfig();
  }, []);

  return (
    <div style={{ width: "100%", height: "100vh", overflowY: "scroll" }}>
      <HashRouter>
        <AppRoutes />
      </HashRouter>
    </div>
  );
}
