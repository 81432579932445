import Header from "../../common/Header";
import { Grid, NoticeBar } from "antd-mobile";
import {
  HandPayCircleOutline,
  PhonebookOutline,
  FlagOutline,
  PieOutline,
  CollectMoneyOutline,
  LocationOutline,
  PhoneFill,
  TravelOutline,
  VideoOutline,
} from "antd-mobile-icons";
import "./bmfw.css";

/**
 * 服务项配置
 * 由于图标问题暂时前端固定，后续有其他地区使用再改成配置读取
 */
const services = [
  {
    icon: <HandPayCircleOutline style={{ color: "#fff", fontSize: 24 }} />,
    colors: ["#00bc70", "#009d5e"], // 渐变颜色组
    name: "电力公司",
    url: "http://weixin.sc.sgcc.com.cn/"
  },
  {
    icon: <CollectMoneyOutline style={{ color: "#fff", fontSize: 24 }} />,
    colors: ["#ff6b6b", "#e55a5a"],
    name: "供水公司",
    url: "http://m.ncswtz.com/"
  },
  {
    icon: <PieOutline style={{ color: "#fff", fontSize: 24 }} />,
    colors: ["#69c0ff", "#4aa8e6"],
    name: "燃气公司",
    url: "http://wx.xiyouweixin.qjcode.com/"
  },
  {
    icon: <FlagOutline style={{ color: "#fff", fontSize: 24 }} />,
    colors: ["#95de64", "#7ec451"],
    name: "电子税务局",
    url: "https://etax.chinatax.gov.cn/"
  },
  {
    icon: <PhonebookOutline style={{ color: "#fff", fontSize: 24 }} />,
    colors: ["#ff9c6e", "#ff884d"],
    name: "企业信用公示",
    url: "https://www.gsxt.gov.cn/index.html"
  },
  {
    icon: <LocationOutline style={{ color: "#fff", fontSize: 24 }} />,
    colors: ["#b37feb", "#9d6bd3"],
    name: "地理信息公共服务",
    url: "https://map.tianditu.gov.cn/"
  },
  {
    icon: <PhoneFill style={{ color: "#fff", fontSize: 24 }} />,
    colors: ["#ffc53d", "#ffa940"],
    name: "电信公司",
    url: "https://www.189.cn/sc/"
  },
  {
    icon: <TravelOutline style={{ color: "#fff", fontSize: 24 }} />,
    colors: ["#36cfc9", "#20c5bf"],
    name: "移动公司",
    url: "https://www.10086.cn/index/sc/index_280_280.html"
  },
  {
    icon: <VideoOutline style={{ color: "#fff", fontSize: 24 }} />,
    colors: ["#ff85c0", "#ff78b0"],
    name: "广电公司",
    url: "https://sccnwechatyyt.sc96655.com/"
  }
];

const Bmfw = () => {
  // 点击服务项处理
  const handleServiceClick = (url: string) => {
    window.open(url, "_blank");
  };

  /**
   * 自定义淡化颜色
   * @param colors
   */
  const linearGradient = (colors: Array<string>) => {
    // 颜色淡化工具函数
    const lightenColor = (hex: string, opacity: number) => {
      return `${hex}${Math.floor(opacity * 255).toString(16).padStart(2, "0")}`;
    };
    const fadedColors = colors.map(c => lightenColor(c, 0.1));
    return `linear-gradient(135deg, ${fadedColors[0]} 0%, ${fadedColors[1]} 100%)`;
  };

  return (
    <div className={"bmfw-page"}>
      <Header name={"便民服务"} />
      <NoticeBar
        content="便民服务由第三方提供，与我们微信公众号无关。我们不对该服务的质量和结果负责，请您自行审慎使用！"
        wrap
        color="alert"
        style={{ fontSize: "12px" }}
      />
      <div className={"page-content"}>
        <Grid columns={3} gap={10}>
          {services.map((service, index) => (
            <Grid.Item
              key={index}
              className="service-item"
              onClick={() => handleServiceClick(service.url)}
            >
              <div className="service-card" style={{
                background: linearGradient(service.colors)
              }}>
                <div
                  className="icon-wrapper"
                  style={{
                    background: `linear-gradient(135deg, ${service.colors[0]} 0%, ${service.colors[1]} 100%)`
                  }}
                >
                  {service.icon}
                </div>
                <div className="service-name">{service.name}</div>
              </div>
            </Grid.Item>
          ))}
        </Grid>
      </div>
    </div>
  );
};

export default Bmfw;
