import Header from '../common/Header';
import Footer from '../common/Footer';
import React, { useEffect, useState } from 'react';
import { requestEstate } from '../util/requestEstate';

export default function Djzx() {
  const [content, setContent] = useState<string>('');
  const inThirdPartyApp = localStorage.getItem('inThirdPartyApp') || '';
  // 页面加载时加载
  useEffect(() => {
    requestEstate.get<any>(`/djzx/djzc/getOne`).then((res) => {
      setContent(res?.result);
    });
  }, []);

  return (
    <>
      {inThirdPartyApp === '1'?null:( <Header name={'登记中心'} /> ) }
      <div dangerouslySetInnerHTML={{ __html: content }} />
      <Footer />
    </>
  );
}
