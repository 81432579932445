import React, { useEffect, useRef, useState } from "react";
import Header from "../../common/Header";
import { requestEstate } from "../../util/requestEstate";
import { DotLoading, Toast, SearchBar, Modal } from "antd-mobile";
import { Pagination } from "antd";
import "./Gsgg.css";
import { SoundOutline } from "antd-mobile-icons";

interface Notice {
  id: string,
  title: string,
  content: string,
  source: string,
  publishTime: string,
  isLink?: string
}

function Gsgg() {
  const [noticeList, setNoticeList] = useState<Array<Notice>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const inThirdPartyApp = localStorage.getItem('inThirdPartyApp') || '';
  const pagination = useRef<{
    current: number,//当前页,从1开始
    size: number,//每页数据个数
    total: number,//数据总数
  }>({
    current: 1,
    size: 5,
    total: 0
  });
  const modalRef = useRef(null);

  //获取数据列表
  async function getList(value?: string) {
    setLoading(true);
    try {
      const res = await requestEstate.get<{
        result: {
          records: Array<Notice>,
          pages: number,
          size: number,
          total: number,
          current: number
        }
      }>("/publish_content/publishContent/list", {
        type: "1",
        title: value,
        pageSize: pagination.current.size,
        pageNo: value ? 1 : pagination.current.current,
        order: "desc",
        column: "createTime"
      });
      setNoticeList(handleRecords(res.result.records));
      pagination.current.current = res.result.current;
      pagination.current.size = res.result.size;
      pagination.current.total = res.result.total;
    } catch (e) {
      console.log(e);
      Toast.show({ icon: "fail", content: "网络错误", duration: 5000 });
    }
    setLoading(false);
  }

  function handleRecords(list: Array<Notice>) {
    if (!list || !Array.isArray(list)) {
      return [];
    }
    // 正则表达式匹配外部链接
    const linkPattern = /<a\s+href="([^"]+)"[^>]*>.*?<\/a>/i;
    return list.map((item: Notice) => {
      const match = item.content.match(linkPattern);
      if (match) {
        // 如果匹配到外部链接
        item.isLink = match[1];
      }
      return item;
    });
  }

  //切换页码
  async function paginationChange(page: number, pageSize: number) {
    pagination.current.current = page;
    await getList();
  }

  //显示详情弹窗
  function showDetail(notice: Notice) {
    if (notice && notice.isLink) {
      window.open(notice.isLink, "_blank");
      return;
    }
    let html: React.ReactNode = <div dangerouslySetInnerHTML={{ __html: notice.content }}></div>;
    Modal.alert({
      title: notice.isLink ? false : notice.title,
      content: html,
      closeOnMaskClick: true,
      getContainer: modalRef.current,
      destroyOnClose: true,
      bodyClassName: notice.isLink ? "LinkModal" : ""
    });
  }

  useEffect(() => {
    getList().then();
  }, []);
  return (
    <div className={"gsgg-page"}>
      {inThirdPartyApp === '1'?null:( <Header name={'工作动态'} /> ) }
      <div className={"page-content"}>
        <div style={{ display: loading ? "flex" : "none" }} className="loading">
          <span>数据加载中</span>
          <DotLoading color="currentColor" />
        </div>
        <div className={"search-bar"}>
          <SearchBar placeholder="请输入关键词" showCancelButton onSearch={(value) => getList(value)} />
        </div>
        <div className={"notice-list"}>
          {
            noticeList.map(notice => {
              return (
                <div className={"notice-item"} key={notice.id} onClick={() => showDetail(notice)}>
                  <div className={"notice-item-icon"}>
                    <SoundOutline fontSize={24} color="var(--adm-color-primary)" />
                  </div>
                  <div className={"notice-item-content"}>
                    <div className={"notice-title"}>{notice.title}</div>
                    <div className={"notice-tips"}>
                      <span>发布时间：{notice.publishTime}</span>
                      <span>来源：{notice.source}</span>
                    </div>
                  </div>
                </div>
              );
            })
          }
        </div>
        <Pagination style={{ display: noticeList.length !== 0 ? "block" : "none" }} className={"pagination"}
                    hideOnSinglePage={true} pageSize={pagination.current.size}
                    current={pagination.current.current} total={pagination.current.total} onChange={paginationChange}
                    showLessItems={true} />
        <div className="empty" style={{ display: noticeList.length === 0 && !loading ? "block" : "none" }}>
          暂无公示公告数据
        </div>
        <div className={"detail-modal"} ref={modalRef}></div>
      </div>
    </div>
  );
}

export default Gsgg;
