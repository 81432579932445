import { request, responseTypes } from "../util/request";
import { ThirdPartyAuthConfig, UserResponse } from "../common/PersonalCenter";
import { Dialog, Toast } from "antd-mobile";

/**
 * 项目入口初始化
 */
class AppConfig {
  // 是否启动第三方用户体系，启用的话，从其它平台跳转进h5，会根据链接上的参数来确定不检测是否在微信环境中
  public enableThirdPartyUser = process.env.REACT_APP_THIRD_PARTY_USER_ENABLE || false;

  constructor() {
    this.init();
  }

  private init() {
    this.initDevor();
    this.initIsInAED();
    this.initLogin();

  }

  private initDevor() {
    // 开发环境 -> 自动设置测试的openid
    process.env.NODE_ENV === "development" && localStorage.setItem("openid", "o1x9k6qXsnrfen5f16JH7EqM9TUs");
  }

  private initLogin() {
    let openid = localStorage.getItem("openid") || "";
    //如果开启第三方跳转过来
    if (this.enableThirdPartyUser) {
      //启动第三方用户体系
      this.initThirdPartyUser(openid);
    }else {
      //存在openid则进行登录校验
      if (openid) {
        this.checkUserInfo();
        return;
      }
      //没有且不是第三方
      this.initWXConfig();
    }

  }

  /**
   * 用户校验校验
   * @private
   */
  private checkUserInfo() {
    request.get<UserResponse>("/auth/user_info").then(data => {
      // 统一数据校验
      if (!data || typeof data !== "object") {
        this.showAuthDialog("register");
        return;
      }
      // 统一认证状态校验
      if (data.faceAuth !== "yes") {
        this.showAuthDialog("auth", data);
        return;
      }
      // 认证通过后统一处理
      localStorage.setItem("user", JSON.stringify(data));
    }).catch(error => {
      this.showErrorToast("用户信息获取失败:" + error);
    });
  }

  /**
   * 第三方用户系统
   * @private
   */
  private initThirdPartyUser(openid: String) {
    request.get<ThirdPartyAuthConfig>("/third-party-auth/config").then(data => {
      //启用配置
      if (data && Object.prototype.hasOwnProperty.call(data, "enable") && data.enable) {
        //初始化安逸达
        this.initAEDConfig(data,openid);
      } else {
        if (openid) {
          this.checkUserInfo();
          return;
        }
        //初始化微信公众号
        this.initWXConfig();
      }
    });
  }

  /**
   * 安逸达
   * @private
   */
  private initAEDConfig(data: ThirdPartyAuthConfig,wxOpenid: String) {
    const urlParams = this.getAllSearchParams();
    //获取安逸达的参数
    const isAuthFirms = data.authFirmsKey === "aed";
    const appId = urlParams.get("app_id");
    const code = urlParams.get("code") || "";
    const openId = urlParams.get("open_id");
    //必须要四个参数才能判断第三方，因为默认微信isAuthFirms也是true
    let aedCode = localStorage.getItem("aedCode") || "";
    if (aedCode && aedCode === code) {
      return;
    }else if(!aedCode && wxOpenid){
      this.checkUserInfo();
      return;
    }
    if (isAuthFirms && code && appId && openId) {
      this.getThirdAuthUser({ 'code':code, 'openId': openId,'appId':appId},code);
    } else {
      this.initWXConfig();
      // this.getAccessToken(code);
    }
  }

  /**
   * 进页面就初始化是否在安e达环境中
   * @private
   */
  private initIsInAED() {
    const urlParams = this.getAllSearchParams();
    //获取安逸达的参数
    const appId = urlParams.get("app_id");
    const code = urlParams.get("code") || "";
    const openId = urlParams.get("open_id");
    //必须要四个参数才能判断第三方，因为默认微信isAuthFirms也是true
    if (code && appId && openId) {
      localStorage.setItem("inThirdPartyApp", "1");
    }
  }
  /**
   * 获取第三方用户信息
   * @private
   */
  private getThirdAuthUser(params: object,code: string) {
    request.post<UserResponse>("/third-party-auth/user-info", params).then(res => {
      //缓存第三方应用：安e达，openid
      localStorage.setItem("openid", res.openid);
      //缓存第三方应用：安e达，用户信息
      localStorage.setItem("user", JSON.stringify(res));
      //缓存第三方应用：安e达，code，用于路由跳转后返回到首页，不重复根据code获取用户信息，因为此时的code已经失效（安e达code使用一次后即失效）
      localStorage.setItem("aedCode", code);
      //不加延迟可能会导致获取用户信息时openid没有获取到，或者改成同步也行
      // setTimeout(() => {
      //   this.checkUserInfo();
      // }, 100);
    }).catch(e => {
      this.showErrorToast(e && e.message ? e.message : "获取第三方用户信息失败！");
    });
  }

  /**
   * 默认微信公众号
   * @private
   */
  private initWXConfig() {
    const urlParams = this.getAllSearchParams();
    const code = urlParams.get("code");
    if (!code) {
      this.getAuthCode();
      return;
    }
    this.getAccessToken(code);
  }


  /**
   * 获取URL参数
   * @param url
   * @private
   */
  public getAllSearchParams(url?: string) {
    const parsedUrl = new URL(url || window.location.href);

    // 首先处理 url 的查询参数部分
    let searchString = parsedUrl.search;

    // 然后处理 hash 部分的查询参数部分
    const hash = parsedUrl.hash;
    const hashIndex = hash.indexOf("?");
    if (hashIndex !== -1) {
      searchString += (searchString ? "&" : "") + hash.substring(hashIndex + 1);
    }

    return new URLSearchParams(searchString);
  }

  /**
   * 获取用户登录code
   * @private
   */
  private getAuthCode() {
    request.get<string>("/auth/auth_url").then(res => {
      if (res) {
        window.location.href = res;
      }
    }).catch(e => {
      this.showErrorToast(e);
    });
  }

  /**
   * 通过code获取用户信息
   * @param code
   * @private
   */
  private getAccessToken(code: string) {
    // 获取code后，获取openid
    request
      .post<string>("/auth/access_token", { code: code })
      .then(res => {
        if (res) {
          localStorage.setItem("openid", res);
          //稍微增加延迟，解决openid没获取到的问题，改成同步也行
          setTimeout(() => {
            this.checkUserInfo();
          }, 100);
        }
      }).catch(error => {
      this.showErrorToast(error);
    });
  }


  /**
   * 实名认证跳转
   * @param type
   * @param data
   * @private
   */
  private showAuthDialog(type: "register" | "auth", data?: UserResponse) {
    const dialogConfig: any = {
      register: {
        content: "您还未注册，是否立即注册？"
      },
      auth: {
        content: data?.thirdPartyAuthConfig?.enable
          ? (data.thirdPartyAuthConfig.authTipMsg || "您未实名认证，不能进行操作！")
          : "未进行实名认证，是否前往认证？"
      }
    };

    Dialog.confirm({
      content: dialogConfig[type].content,
      onConfirm: () => {
        window.location.href = "/#/me";
      }
    });
  }

  /**
   * 异常信息弹窗
   * @param error
   * @private
   */
  private showErrorToast(error: any) {
    Toast.show({
      content: error || "网络异常！",
      position: "top"
    });
  }
}

export default AppConfig;
